import ApiHandler from "./ApiHandler";

import { KLAVIYO_ROUTES } from "./const";

export type ValidationRequestBody = {
  listId?: string;
  profiles: [
    {
      $first_name?: string;
      $last_name?: string;
      email?: string;
    }
  ];
};

const KlaviyoAPI = {
  validateCode: (body: ValidationRequestBody) =>
    ApiHandler.post({ url: KLAVIYO_ROUTES.base(), body }),
};

export default KlaviyoAPI;
