import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline, Link } from "@material-ui/core";
import { ThemeProvider, wellColors } from "@welldigital/components";
import { ThemingProps } from "@welldigital/components/theming";
import {
  useAuthentication,
  AUTHENTICATED_STATE,
} from "@welldigital/ui-common/Authentication";
import { persistor } from "./store";
import ScrollToTop from "components/ScrollToTop";
import OnlineRoutes from "./routes/";
import "./styles.css";
import { analytics } from "@welldigital/ui-common";
import AccountsAPI from "utils/api/AccountsAPI";
import { TAccount } from "app/store/reducer/account/types";
import { CookiesProvider } from "react-cookie";
import { CookieManagerModal, Panel } from "components/CookieManagerModal/index";
import { cookie } from "@welldigital/ui-common/Components/CookiesManager/helpers";
import { CookiesPreferenceNames } from "@welldigital/ui-common/Components/CookiesManager/constants";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import RightArrow from "components/CookieManagerModal/rightArrow";

const onlineCustomTheming: ThemingProps = {
  // TODO: remove when the design system is finished
  props: {
    MuiLink: {
      color: "secondary",
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1rem",
      },
    },
    MuiInputLabel: {
      root: {
        color: wellColors.elixir[300],
      },
    },
    MuiInputBase: {
      input: {
        color: wellColors.elixir[300],

        "&::placeholder": {
          color: wellColors.elixir[300],
          opacity: 1,
        },

        "&:-ms-input-placeholder": {
          color: wellColors.elixir[300],
        },

        "&::-ms-input-placeholder": {
          color: wellColors.elixir[300],
        },

        "&:-webkit-autofill": {
          transition: "background-color 600000s 0s, color 600000s 0s",
        },
        "&:-webkit-autofill:focus": {
          transition: "background-color 600000s 0s, color 600000s 0s",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          position: "relative",
        },
      },
    },
  },
  palette: {
    primary: {
      light: wellColors.zen[50],
      main: wellColors.zen[500],
      dark: wellColors.zen[900],
    },
    secondary: {
      light: wellColors.zen[50],
      main: wellColors.zen[50],
      dark: wellColors.zen[100],
      contrastText: wellColors.zen[900],
    },
    error: {
      main: wellColors.bloom[800],
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookiesPreferencesLink: {
      position: "absolute",
      bottom: 0,
      left: 0,
      padding: 16,
      display: "block",
      zIndex: 100,
      textDecoration: "none",
      fontSize: "16px",
      lineHeight: "25.6px",
      fontWeight: 500,
      color: "#fff",
      backgroundColor: "#083149",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);

const setUserId = async (id?: string | null) => {
  if (id === null) {
    analytics.setUser(null);
    return;
  }

  try {
    const response = await AccountsAPI.getAccount();
    const data: TAccount = response.data;
    analytics.setUser(data.userId);
  } catch (err) {
    console.error(err);
  }
};

const App = () => {
  const classes = useStyles();

  const { update } = useIntercom();
  const { authenticatedState, email } = useAuthentication();

  const isAuthenticated = authenticatedState === AUTHENTICATED_STATE.YES;

  const [currentCookieBannerPanel, setCurrentCookieBannerPanel] = useState(
    cookie.get(CookiesPreferenceNames.cookiePreferencesSet) !== "true"
      ? Panel.home
      : null
  );

  const closeCookiesBanner = useCallback(() => {
    setCurrentCookieBannerPanel(null);
  }, []);

  const launchCookiesBanner = useCallback((evt) => {
    evt.preventDefault();
    setCurrentCookieBannerPanel(Panel.preferences);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      update({ email });
    }
  }, [isAuthenticated, email, update]);

  useEffect(() => {
    authenticatedState === AUTHENTICATED_STATE.YES && setUserId();
    authenticatedState === AUTHENTICATED_STATE.NO && setUserId(null);
  }, [authenticatedState]);

  return (
    <ThemeProvider themeName={"services"} custom={onlineCustomTheming}>
      <CookiesProvider>
        <CssBaseline />
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollToTop />
            <OnlineRoutes />
            <CookieManagerModal
              onDone={closeCookiesBanner}
              onPanelChangeIntent={setCurrentCookieBannerPanel}
              panel={currentCookieBannerPanel}
            />
            <Link
              onClick={launchCookiesBanner}
              className={classes.cookiesPreferencesLink}
              href={"#"}
            >
              <span
                style={{
                  transform: "rotate(-90deg)",
                  display: "inline-block",
                  marginRight: 10,
                  textSizeAdjust: "100%",
                }}
              >
                <RightArrow />
              </span>
              Cookie preferences
            </Link>
          </Router>
        </PersistGate>
      </CookiesProvider>
    </ThemeProvider>
  );
};
export default App;

//da52ad01-21dc-46aa-915b-3d4c79e9bf6d
