import React, { useState, useCallback } from "react";
import { Modal } from "@material-ui/core";
import CookieManagerConsentView from "@welldigital/ui-common/Components/CookiesManager/components/CookieManagerConsentView";
import CookieManagerPreferencesView from "@welldigital/ui-common/Components/CookiesManager/components/CookieManagerPreferencesView";
import { CookiesPreferenceNames } from "@welldigital/ui-common/Components/CookiesManager/constants";
import { cookiesViewsStyles } from "@welldigital/ui-common/Components/CookiesManager/cookiesViewsStyles";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  clearAllCookies,
  cookie,
} from "@welldigital/ui-common/Components/CookiesManager/helpers";
import { analytics } from "@welldigital/ui-common/Analytics";
import { Optimize } from "@welldigital/ui-common/Optimize";
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      color: "#333333",
      "& .MuiBox-root .MuiTypography-h5": {
        fontWeight: 600,
        lineHeight: "32px",
      },
      "& .MuiContainer-root .MuiTypography-h6": {
        lineHeight: "27px",
      },
      "& .MuiBox-root .MuiContainer-root .MuiTypography-h5": {
        lineHeight: "36px",
        "& button": {
          fontFamily: "GilroyAlt",
        },
      },
    },
  })
);

export enum Panel {
  home = "home",
  preferences = "preferences",
}

export type CookieManagerModalProps = {
  onDone: () => void;
  onPanelChangeIntent: (panel: Panel | null) => void;
  panel: Panel | null;
};

const panels = new Set<Panel | null>([Panel.home, Panel.preferences]);

export const CookieManagerModal: React.FC<CookieManagerModalProps> = (
  props
) => {
  const className = cookiesViewsStyles();
  const classes = useStyles();

  const {
    cookiePreferencesSet,
    cookiePreferencesAllowAnalytics,
    cookiePreferencesAllowFunctional,
    cookiePreferencesAllowAds,
  } = CookiesPreferenceNames;
  const { onDone, panel, onPanelChangeIntent } = props;
  const isOpen = panels.has(panel);
  const isPreferenceSet = cookie.get(cookiePreferencesSet) === "true";
  const [isFunctionalChecked, setFunctionalChecked] = useState<boolean>(
    cookie.get(cookiePreferencesAllowFunctional) === "true"
  );
  const [isAnalyticsChecked, setAnalyticsChecked] = useState<boolean>(
    cookie.get(cookiePreferencesAllowAnalytics) === "true"
  );
  const [isAdsChecked, setAdsChecked] = useState<boolean>(
    cookie.get(cookiePreferencesAllowAds) === "true"
  );

  const cookieExpires = new Date(+new Date() + 3600 * 24 * 365 * 1000);

  const writePreference = useCallback(
    (key: string, value: boolean) => {
      return cookie.set(key, value, {
        path: "/",
        expires: cookieExpires,
      });
    },
    [cookieExpires]
  );

  const handleAcceptAllCookies = useCallback(() => {
    clearAllCookies();
    setFunctionalChecked(true);
    setAnalyticsChecked(true);
    setAdsChecked(true);
    writePreference(cookiePreferencesSet, true);
    writePreference(cookiePreferencesAllowAnalytics, true);
    writePreference(cookiePreferencesAllowAds, true);
    writePreference(cookiePreferencesAllowFunctional, true);
    onDone();
  }, [
    cookiePreferencesSet,
    cookiePreferencesAllowAnalytics,
    cookiePreferencesAllowAds,
    cookiePreferencesAllowFunctional,
    onDone,
    writePreference,
  ]);

  const handlePreference = useCallback(() => {
    clearAllCookies();
    writePreference(cookiePreferencesSet, true);
    writePreference(cookiePreferencesAllowAnalytics, isAnalyticsChecked);
    writePreference(cookiePreferencesAllowAds, isAdsChecked);
    writePreference(cookiePreferencesAllowFunctional, isFunctionalChecked);
    onDone();
  }, [
    writePreference,
    cookiePreferencesSet,
    cookiePreferencesAllowAnalytics,
    isAnalyticsChecked,
    cookiePreferencesAllowAds,
    isAdsChecked,
    cookiePreferencesAllowFunctional,
    isFunctionalChecked,
    onDone,
  ]);

  const handleFunctionalCookiesPreference = useCallback(() => {
    setFunctionalChecked((isFunctionalChecked) => !isFunctionalChecked);
  }, []);
  const handleAnalyticsCookiesPreference = useCallback(() => {
    setAnalyticsChecked((isAnalyticsChecked) => !isAnalyticsChecked);
  }, []);
  const handleAdsCookiesPreference = useCallback(() => {
    setAdsChecked((isAdsChecked) => !isAdsChecked);
  }, []);

  if (cookie.get("cookie-preferences-allow-analytics") === "true") {
    analytics.init();

    if (process.env.REACT_APP_OPTIMIZE_ID && process.env.REACT_APP_GTAG_ID) {
      Optimize.registerOptimize(
        process.env.REACT_APP_OPTIMIZE_ID,
        process.env.REACT_APP_GTAG_ID
      );
    }

    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
    }
  }

  const panelToRender =
    panel === Panel.home ? (
      <CookieManagerConsentView
        onCustomizePreferences={onPanelChangeIntent.bind(
          null,
          Panel.preferences
        )}
        onAcceptAllCookies={handleAcceptAllCookies}
      />
    ) : (
      <CookieManagerPreferencesView
        isPreferenceSet={isPreferenceSet}
        onBack={onPanelChangeIntent.bind(null, Panel.home)}
        onClose={onPanelChangeIntent.bind(null, null)}
        onAcceptAllCookies={handleAcceptAllCookies}
        onPreferenceSaving={handlePreference}
        onChangeFunctionalCookiesPreference={handleFunctionalCookiesPreference}
        onChangeAnalyticsCookiesPreference={handleAnalyticsCookiesPreference}
        onChangeAdsCookiesPreference={handleAdsCookiesPreference}
        isFunctionalChecked={isFunctionalChecked}
        isAnalyticsChecked={isAnalyticsChecked}
        isAdsChecked={isAdsChecked}
      />
    );
  return (
    <Modal className={className.modalBackdrop} open={isOpen}>
      <div className={classes.panel}>{panelToRender}</div>
    </Modal>
  );
};

export default CookieManagerModal;
