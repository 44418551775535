export const SUBSCRIPTIONS_ACTIONS = {
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",
  GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
  SET_SUBSCRIPTIONS_STATUS: "SET_SUBSCRIPTIONS_STATUS",
};

export const SUBSCRIPTION_STATUSES = {
  REQUIRES_PHARMACIST_APPROVAL: "Requires pharmacist approval",
  ACTIVE: "Active",
  CANCELLED: "Cancelled",
  PAUSED_NO_APPROVAL: "No approval",
  PAUSED_FAILED_PAYMENT: "Failed payment",
  APPROVED: "Approved",
  PHARMACIST_DECLINED: "Pharmacist declined",
};
