export const LOGIN_OPTIONS_PATH = "/login-options";
export const PRODUCT_SELECT_PATH =
  "/order/:onlineServiceId(ed)/product-selection";
export const ACCOUNT_DETAILS_PATH =
  "/order/:onlineServiceId(ed)/account-details";
export const REGISTER_PATH_PATH = "/register";
export const ORDER_PAYMENT_PATH = "/order/:onlineServiceId(ed)/payment";

export const ACCOUNT_ORDERS_PATH = "/account/:orderType(orders|subscriptions)";
export const ORDER_DETAILS_PATH = "/account/:orderType(order|subscription)/:id";

export const TERMS_AND_CONDITIONS_PATH = "/online/terms-and-conditions";
